import React from 'react'
import { graphql } from 'gatsby';
import loadable from "@loadable/component";
import Layout from '../components/layout'
import Seo from "../components/seo"

const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const AreaGuideLanding = loadable(() => import("../components/AreaGuideLanding/AreaGuideLanding"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));

const AreaguideLandingTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    const areaGuideData = data?.allStrapiAreaGuide?.edges

    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>
            <BreadcrumbModule
                subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
                parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
                title={PageData.title}
            />
            <div className='layout-grey-bg-theme'>
                {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                    return (
                        <>
                            {module.strapi_component === "page-modules.plain-content" && <StaticIntro {...module} pagename={PageData.title} staticClass="listing-page-intro" />}
                        </>
                    )
                })}

                <AreaGuideLanding areaguides={areaGuideData} />
            </div>

            <CtaModule />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData.title} description={PageData.title} />
    )
}
export default AreaguideLandingTemplate

export const query = graphql`
query ($page_id: String) {
	strapiPage(id: {eq: $page_id}) {
        ...PageFragment
		Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
	}
	allStrapiAreaGuide(filter: {publish: {eq: true}}) {
		edges {
			node {
				...AreaGuideFragment
				add_content {
					... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
						...PlainContentFragment
					}
					... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
						...ImageFragment
					}
				}
			}
		}
	}
}`